import {
  Box,
  ChakraProvider,
  useDisclosure,
} from "@chakra-ui/react"
import * as React from "react"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

import Header from "./header"
import Footer from "../components/footer"
import CustomFonts from "../theme/customFonts"
import theme from "../theme"
import ScheduleConsultPopup from "./scheduleConsultPopup"
import { useSessionStorage } from "../components/CoreUI/utils/hooks"

interface PageContainerProps {
  children: React.ReactNode
  showAssociations?: boolean
  showNewsletter?: boolean
}

function PageContainer({
  children,
  showAssociations,
  showNewsletter,
}: PageContainerProps): JSX.Element {
  gsap.registerPlugin(ScrollTrigger)

  const popup = useDisclosure()

  const [fired, setFired] = useSessionStorage(`consultPopup`, false)
  const [hasInteracted, setHasInteracted] = useSessionStorage(
    `hasInteracted`,
    false
  )
  const popupTimerRef = React.useRef<any>()
  const pixelRef = React.useRef<HTMLDivElement>()

  React.useEffect(() => {
    if (!hasInteracted) {
      ScrollTrigger.create({
        end: `top top-=${window.innerHeight * 0.33}px`,
        once: true,
        onToggle: ({ progress }) => {
          if (progress === 1) {
            setHasInteracted(true)
          }
        },
        start: `top top+=72px`,
      })
    }
  }, [hasInteracted])

  React.useEffect(() => {
    if (true || hasInteracted) {
      if (!fired) {
        clearTimeout(popupTimerRef.current)
        popupTimerRef.current = setTimeout(openPopup, 7000)
      }
    }
  }, [fired, hasInteracted])

  const openPopup = (): void => {
    setFired(true)
    setTimeout(popup.onOpen, 3000)
  }

  return (
    <ChakraProvider theme={theme}>
      <CustomFonts />
      <div
        ref={pixelRef}
        style={{
          height: 0,
          left: 0,
          position: `absolute`,
          top: 0,
          width: 0,
          zIndex: -1,
        }}
      />
      <Header />
      <Box
        id="content"
        mt="4.5rem"
        mx="auto"
        minH="76vh"
        maxW="100vw"
        overflowX="hidden"
      >
        {children}
      </Box>
      <Footer
        showAssociations={showAssociations}
        showNewsletter={showNewsletter}
      />
      <ScheduleConsultPopup {...popup} />
    </ChakraProvider>
  )
}

export default PageContainer
