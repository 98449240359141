import {
  chakra,
  Flex,
  IconButton,
  useDisclosure,
  useUpdateEffect,
} from "@chakra-ui/react"
import * as React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { HamburgerIcon } from "@chakra-ui/icons"

import Logo from "./logo"
import { MobileNav } from "./mobileNav"
import { DesktopNav } from "./desktopNav"

function Content({ latestBlog, pixel }) {
  const headerRef = React.useRef<HTMLDivElement>(null)
  const mobileNav = useDisclosure()
  const mobileNavBtnRef = React.useRef<HTMLButtonElement>()
  const [menuIsOpen, setMenuIsOpen] = React.useState<boolean>(false)
  const [navShadow, setNavShadow] = React.useState<`md` | `none`>(`none`)

  React.useEffect(() => {
    const observer = new IntersectionObserver(
      (entries: IntersectionObserverEntry[]) => {
        entries.forEach((entry: IntersectionObserverEntry) => {
          if (entry.isIntersecting) {
            setNavShadow(`none`)
          } else if (!entry.isIntersecting && !menuIsOpen) {
            setNavShadow(`md`)
          }
        })
      },
      { threshold: 1.0, rootMargin: `0px 0px 0px 0px` }
    )
    observer.observe(pixel.current)

    return () => {
      observer.disconnect()
    }
  }, [])

  const toggleShadow = (isOpen: boolean): void => {
    setMenuIsOpen(isOpen)
    if (isOpen) {
      setNavShadow(`none`)
    } else if (!isOpen) {
      setNavShadow(`md`)
    }
  }

  useUpdateEffect(() => {
    mobileNavBtnRef?.current?.focus()
  }, [mobileNav.isOpen])

  return (
    <>
      <chakra.div d={{ base: `block`, lg: `none` }} h="100%" w="100%">
        <Flex
          align="center"
          bg="white"
          h="100%"
          justify="space-between"
          pos="relative"
          px="6"
          shadow={navShadow}
          ref={headerRef}
          w="100%"
          zIndex="10"
        >
          <Link to="/" aria-label="Link to homepage">
            <Logo />
          </Link>
          <Flex
            align="center"
            color="gray.400"
            justify="flex-end"
            maxW={{ base: `90px`, md: `500px` }}
            w="100%"
          >
            <MobileNav isOpen={mobileNav.isOpen} onClose={mobileNav.onClose} />
            <IconButton
              aria-label="Open menu"
              color="gray.800"
              fontSize="30px"
              icon={<HamburgerIcon />}
              onClick={mobileNav.onOpen}
              ref={mobileNavBtnRef}
              variant="ghost"
            />
          </Flex>
        </Flex>
      </chakra.div>
      <chakra.div
        d={{ base: `none`, lg: `block` }}
        h="100%"
        shadow={navShadow}
        transition="0.3s ease-out"
        w="100%"
      >
        <DesktopNav latestBlog={latestBlog} onToggleDropdown={toggleShadow} />
      </chakra.div>
    </>
  )
}

function Header(props: object) {
  const { blogs } = useStaticQuery(graphql`
    query {
      blogs: allContentfulBlogPost(
        limit: 1
        sort: { order: DESC, fields: datePublished }
      ) {
        nodes {
          slug
          title
        }
      }
    }
  `)

  const latestBlog = blogs.nodes[0]

  // pixelRef is an invisible "pixel" that the nav menu intersection observer
  // references when deciding if it's time to render a shadow.
  const pixelRef = React.useRef<HTMLDivElement>(null)
  return (
    <>
      <chakra.div
        aria-hidden
        bg="transparent"
        h="0px"
        left="50%"
        pos="absolute"
        ref={pixelRef}
        top="100px"
        w="0px"
        zIndex="-1"
      />
      <chakra.header
        bg="white"
        left="0"
        pos="fixed"
        right="0"
        shadow="none"
        top="0"
        width="full"
        zIndex="3"
        {...props}
      >
        <chakra.div bg="white" h="4.5rem" mx="auto">
          <Content latestBlog={latestBlog} pixel={pixelRef} />
        </chakra.div>
      </chakra.header>
    </>
  )
}

export default Header
