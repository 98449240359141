import * as React from "react"
import {
  Button,
  ButtonProps,
  Link as ChakraLink,
  LinkProps as ChakraLinkProps,
} from "@chakra-ui/react"
import { ArrowDownIcon, ArrowForwardIcon } from "@chakra-ui/icons"
import { Link as GatsbyLink } from "gatsby"
import styled from "@emotion/styled"
import gsap from "gsap"
import { ScrollToPlugin } from "gsap/ScrollToPlugin"

export const BackgroundBars = styled.span`
  background: ${({ color }) =>
    `linear-gradient(transparent 80%, ${color} 80%)`};
  background-size: 10% 10%;
  overflow: hidden;
`

export const Underlined = styled.span`
  background: ${({ color }) =>
    `linear-gradient(transparent 80%, ${color} 80%)`};
`

interface PointyButtonProps extends ButtonProps {
  to: string
}

export function PointyButton(props: PointyButtonProps): JSX.Element {
  return (
    <Button
      as={GatsbyLink}
      _hover={{ shadow: `lg` }}
      shadow="md"
      {...props}
      role="group"
      rightIcon={
        <ArrowForwardIcon
          _groupHover={{ transform: `translateX(4px)` }}
          transition="0.3s"
        />
      }
    />
  )
}

export type ScrollDownButtonProps = {
  offsetY?: number
  target: React.MutableRefObject<HTMLElement>
}

export function ScrollDownButton({
  offsetY = 0,
  target,
}: ScrollDownButtonProps): JSX.Element {
  gsap.registerPlugin(ScrollToPlugin)

  const arrowRef = React.useRef<SVGSVGElement>(null)
  const tl = React.useRef<gsap.core.Timeline>(
    gsap.timeline({ repeat: -1, repeatDelay: 2 })
  )

  React.useEffect(() => {
    if (arrowRef.current) {
      tl.current.to(arrowRef.current, {
        y: -60,
        duration: 1.25,
        ease: `power2.out`,
      })
      tl.current.to(arrowRef.current, {
        y: 0,
        duration: 1.25,
        ease: `bounce.out`,
      })

      const observer = new IntersectionObserver(
        entries => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              tl.current.play()
            } else {
              tl.current.restart()
              tl.current.pause()
            }
          })
        },
        { threshold: 1.0 }
      )

      observer.observe(arrowRef.current)
    }

    return () => {
      tl.current.kill()
    }
  }, [])

  return (
    <Button
      aria-label="Scroll down to content"
      borderBottom="2px"
      borderBottomColor="white"
      onClick={() => {
        if (typeof window !== `undefined`) {
          gsap.to(window, {
            scrollTo: { offsetY, y: target.current },
          })
        }
      }}
      onMouseEnter={() => tl.current.pause()}
      onMouseLeave={() => tl.current.play()}
      pb={0}
      rounded="none"
      variant="unstyled"
      w="48px"
    >
      <ArrowDownIcon boxSize="48px" color="white" ref={arrowRef} />
    </Button>
  )
}

export type LinkProps = ChakraLinkProps & {
  children: string
  href: string
}

export function Link(props: LinkProps) {
  const { children, href, ...rest } = props
  return (
    <ChakraLink
      bgGradient="linear(transparent 40%, red.200 40%)"
      bgSize="100% 200%"
      bgPos="0% 0%"
      color="black"
      fontWeight="medium"
      href={href}
      transition="0.3s"
      _hover={{ backgroundPosition: `0% 100%` }}
      {...rest}
    >
      {children}
    </ChakraLink>
  )
}
