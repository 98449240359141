const styles = {
  global: {
    ".decor": {
      fontFamily: `IvyPresto Display`,
    },
    ".rounded": {
      picture: {
        rounded: `lg`,
      },
    },
    ".blog-content, .client-story": {
      a: {
        color: "black",
        background:
          "linear-gradient(transparent 40%, var(--chakra-colors-red-200) 40%)",
        backgroundSize: "100% 200%",
        backgroundPosition: "0% 0%",
        fontWeight: "medium",
        transition: "0.3s",
        _hover: {
          backgroundPosition: "0% 100%",
        },
      },
      blockquote: {
        bg: "gray.100",
        borderInlineStartWidth: "4px",
        borderInlineStartColor: "red.500",
        fontWeight: "medium",
        margin: "15px 0",
        padding: "2px 15px",
        rounded: "base",
        shadow: "sm",
        p: {
          color: "gray.600",
        },
      },
      h1: {
        borderBottom: "1px solid var(--chakra-colors-gray-200)",
        fontSize: "5xl",
      },
      h2: {
        borderBottom: "1px solid var(--chakra-colors-gray-100)",
        fontSize: "4xl",
      },
      h3: {
        fontSize: "3xl",
      },
      h4: {
        fontSize: "2xl",
      },
      h5: {
        fontSize: "xl",
      },
      "h1, h2, h3, h4, h5": {
        lineHeight: "1.7",
        margin: "1em 0 15px",
      },
      "h1, h2": {
        fontFamily: "heading",
        fontWeight: "extrabold",
      },
      "h3, h4, h5": {
        fontWeight: "bold",
      },
      img: {
        mx: "auto",
        my: "4",
        maxH: "50vh",
        rounded: "lg",
        shadow: "lg",
      },
      "p, li": {
        fontSize: "lg",
        lineHeight: "base",
        margin: "15px auto",
      },
      "p:first-of-type, li:first-of-type": {
        marginTop: "0px",
      },
    },
    ".gatsby-image-wrapper:not(.squared) > picture > img": {
      borderRadius: "lg",
    },
    ".invisiscroll": {
      scrollbarWidth: `none`,
    },
    ".invisiscroll::-webkit-scrollbar": {
      height: `0px`,
      width: `0px`,
    },
    ".plan-features": {
      td: {
        textAlign: "center",
      },
      th: {
        textAlign: "center",
      },
    },
  },
}

export default styles
