import {
  Box,
  Button,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  UseDisclosureProps,
} from "@chakra-ui/react"
import * as React from "react"
import { Link as GatsbyLink } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

function HomesiteEvaluationPopup({
  isOpen,
  onClose,
}: UseDisclosureProps): JSX.Element {
  return (
    <Modal allowPinchZoom isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay bg="blackAlpha.800" />
      <ModalContent maxW="95vw" w={500}>
        <ModalHeader borderTopRadius="lg" m={0} p={0}>
          <Box pos="relative" w="100%">
            <StaticImage
              alt="A Sundog home on a steep, waterfront lot in Tuckaseegee, NC."
              className="squared"
              layout="fixed"
              height={300}
              src="../images/home-on-bear-lake.jpg"
              style={{
                borderTopLeftRadius: `var(--chakra-radii-md)`,
                borderTopRightRadius: `var(--chakra-radii-md)`,
                width: `100%`,
              }}
            />
            <Flex
              align="center"
              bg="blackAlpha.600"
              flexDir="column"
              h="300px"
              justify="center"
              left="0"
              pos="absolute"
              textAlign="center"
              top="0"
              w="100%"
            >
              <Text
                borderBottom="2px solid red"
                color="white"
                fontFamily="heading"
                fontSize="3xl"
                fontWeight="bold"
              >
                How Can We Help?
              </Text>
            </Flex>
            <Image
              alt="Energy Star"
              bottom="20px"
              h="50px"
              pos="absolute"
              right="10px"
              src="/energy-star-partner.svg"
            />
            <Image
              alt="Energy Star"
              bottom="20px"
              filter="brightness(1000%)"
              h="50px"
              left="10px"
              pos="absolute"
              src="/green-built-alliance-partner.svg"
            />
            <ModalCloseButton color="gray.300" size="lg" />
          </Box>
        </ModalHeader>
        <ModalBody mx="auto" py={4} w="100%">
          <Text>
            We understand that building a custom home feels daunting — never
            more so than deciding when, where, and how to take the first step.
            <br />
            <br />
            That's why we offer free, impartial, no-obligation phone
            consultations. We'll spend up to an hour with you talking through
            your concerns, answering your questions, and sharing what we've
            learned over our decades of experience.
            <br />
            <br />
            Become a better-informed buyer. Schedule your consult today.
          </Text>
        </ModalBody>
        <ModalFooter justifyContent={[`space-between`, `space-evenly`, `start`]} gap={{ md: 8 }} mb={4}>
          <Button
            as={GatsbyLink}
            colorScheme="green"
            to="/schedule-consultation/"
          >
            Schedule My Consult
          </Button>
          <Button onClick={onClose} variant="outline">
            No, thanks
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default HomesiteEvaluationPopup
