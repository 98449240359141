import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  Button,
  FormControl,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Tooltip,
} from "@chakra-ui/react"
import * as React from "react"
import { CheckIcon, EmailIcon } from "@chakra-ui/icons"
import { Field, Form, Formik, FormikHelpers } from "formik"

import { NewsletterFormProps } from "./forms"
import { MarketingContext } from "../components/CoreUI/MarketingContext"
import { fireGtmEvent } from "../utils/gtm"
import type CoreUI from "../components/CoreUI/types"
import { formikHelpers } from "@avldev/gatsby-active-campaign-forms"

function NewsletterForm({
  buttonColor = `green`,
  buttonId = `newsletter-form`,
  buttonText = `Sign Up`,
  errorHeader = `Error`,
  errorMessage = `Something went wrong.`,
  eventLabel = `Newsletter`,
  fieldWidth = `275px`,
  layout = `inline`,
  size = `md`,
  successHeader = `Success`,
  successMessage = `Thanks for signing up!`,
}: NewsletterFormProps): JSX.Element {
  const mkt = React.useContext<CoreUI.TMarketingContext>(MarketingContext)
  const formRef = React.useRef<HTMLFormElement>(null)
  const utmParams = mkt?.getUtmParamsObject()

  const direction = layout === `inline` ? `row` : `column`

  function validateEmail(email?: string): string {
    if (!email) {
      return `Oops — an email address is required.`
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
      return `Hmm... This doesn't look like a valid email.`
    }
    return ``
  }

  const onSubmit = (values: {}, actions: FormikHelpers<{}>) => {
    const { current: form } = formRef

    const onSubmitEnd = (status) => {
      actions.setSubmitting(false)
      actions.setStatus(status)
    }

    const onSubmitSuccess = () => {
      fireGtmEvent({
        eventName: `form_submission`,
        eventAction: `Form Submission`,
        eventCategory: `Newsletter Signup`,
        eventLabel,
      })
    }

    formikHelpers.onSubmit(
      form,
      { onSubmitEnd, onSubmitSuccess },
      process.env.NODE_ENV
    )
  }

  const alertHeight = size === `lg` ? `60px` : `40px`

  return (
    <Formik initialValues={{}} onSubmit={onSubmit}>
      {props => {
        return (
          <Form action="https://sundoghomes.activehosted.com/proc.php" id="newsletter-form" ref={formRef}>
            {props.status ? (
              <Alert h={alertHeight} rounded="md" status={props.status === 200 ? `success` : `error`}>
                <AlertIcon />
                <AlertTitle mr={2}>{props.status === 200 ? successHeader : errorHeader}</AlertTitle>
                <AlertDescription>{props.status === 200 ? successMessage : errorMessage}</AlertDescription>
              </Alert>
            ) : (
              <Stack direction={{ base: `column`, sm: direction }} spacing={2}>
                <input type="hidden" name="u" value="19" />
                <input type="hidden" name="f" value="19" />
                <input type="hidden" name="s" />
                <input type="hidden" name="c" value="0" />
                <input type="hidden" name="m" value="0" />
                <input type="hidden" name="act" value="sub" />
                <input type="hidden" name="v" value="2" />
                <input type="hidden" name="or" value="55434ff4f13ef7aa3014c2c59cd2766e" />
                <Field validate={() => validateEmail(props.values[`email`])}>
                  {({ form }) => (
                    <FormControl
                      isInvalid={form.errors.email && form.touched.email}
                      isRequired
                    >
                      <Tooltip
                        hasArrow
                        isOpen={!!form.errors.email && !!form.touched.email}
                        label="Is this a valid email address?"
                        placement="top"
                      >
                        <InputGroup
                          borderColor="gray.400"
                          size={size}
                          w={fieldWidth}
                        >
                          <InputLeftElement pointerEvents="none">
                            <EmailIcon color="gray.400" />
                          </InputLeftElement>
                          <Input
                            name="email"
                            bg="white"
                            id="email"
                            placeholder="Your Email"
                            _placeholder={{ color: `gray.400` }}
                            onChange={props.handleChange}
                          />
                        </InputGroup>
                      </Tooltip>
                    </FormControl>
                  )}
                </Field>
                <Box>
                  <Button
                    colorScheme={buttonColor}
                    id={buttonId}
                    isDisabled={!props.touched || !props.isValid || props.isSubmitting}
                    isLoading={props.isSubmitting}
                    rightIcon={<CheckIcon />}
                    size={size}
                    type="submit"
                    w="100%"
                  >
                    {buttonText}
                  </Button>
                </Box>
              </Stack>
            )}
          </Form>
        )
      }}
    </Formik>
  )
}

export default NewsletterForm
