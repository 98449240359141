import { Box, BoxProps } from "@chakra-ui/react"
import * as React from "react"

import { Associations, Partners } from "../data/svgForLogos"

export type SVGLogoProps = BoxProps & { organization: string | string }

function SVGLogo({ organization, ...rest }: SVGLogoProps) {
  let Category: {}
  if (organization in Associations) {
    Category = Associations
  } else if (organization in Partners) {
    Category = Partners
  } else {
    return
  }
  return (
    <Box
      dangerouslySetInnerHTML={{ __html: Category[organization] }}
      {...rest}
    />
  )
}

export function TwoTenHomebuyersWarranty(props: BoxProps) {
  return <SVGLogo organization="TwoTenHomebuyersWarranty" {...props} />
}

export function AshevilleHomeBuildersAssociation(props: BoxProps) {
  return <SVGLogo organization="AshevilleHomeBuildersAssociation" {...props} />
}

export function BetterBusinessBureau(props: BoxProps) {
  return <SVGLogo organization="BetterBusinessBureau" {...props} />
}

export function EnergyStar(props: BoxProps) {
  return <SVGLogo organization="EnergyStar" {...props} />
}

export function EqualHousingOpportunity(props: BoxProps) {
  return <SVGLogo organization="EqualHousingOpportunity" {...props} />
}

export function FairHousingAct(props: BoxProps) {
  return <SVGLogo organization="FairHousingAct" {...props} />
}

export function GreenBuiltAlliance(props: BoxProps) {
  return <SVGLogo organization="GreenBuiltAlliance" {...props} />
}

export function NHBA(props: BoxProps) {
  return <SVGLogo organization="NHBA" {...props} />
}

export function BuilderTrend(props: BoxProps) {
  return <SVGLogo organization="BuilderTrend" {...props} />
}

export function BuilderTrendIcon(props: BoxProps) {
  return <SVGLogo organization="BuilderTrendIcon" {...props} />
}

export default SVGLogo
