import { extendTheme } from "@chakra-ui/react"
import styles from "./styles"
import fonts from "./fonts"

const overrides = {
  fonts,
  styles,
  components: {
    Modal: {
      baseStyle: {
        dialogContainer: {
          "@supports(height: -webkit-fill-available)": {},
        },
      },
    },
  },
}
const theme = extendTheme(overrides)

export default theme
