import * as React from "react"
import { Icon, Link } from "@chakra-ui/react"
import { EmailIcon, PhoneIcon } from "@chakra-ui/icons"
import { Link as GatsbyLink } from "gatsby"
import { FaClock, FaInfoCircle, FaLocationArrow } from "react-icons/fa"

import { Hours } from "../data/hours"
import { getWeekdaySummary } from "../utils/timeUtils"

interface IHelpItem {
  title: string
  icon: JSX.Element
  content: string | JSX.Element
}

const NeedHelpItems: IHelpItem[] = [
  {
    title: `Hours`,
    icon: <Icon as={FaClock} />,
    content: (
      <>
        {getWeekdaySummary(Hours, `%d1 - %d2, `)}
        <br />
        Evenings and weekends are by appointment only. You can{" "}
        <Link as={GatsbyLink} color="red.500" to="/design-studio#schedule/">
          schedule an appointment here
        </Link>
        .
      </>
    ),
  },
  {
    title: `Call Us`,
    icon: <PhoneIcon />,
    content: (
      <Link as="a" color="red.500" href="tel:8287745720">
        (828) 774-5720
      </Link>
    ),
  },
  {
    title: `Visit Us`,
    icon: <Icon as={FaLocationArrow} />,
    content: (
      <>
        18 Regent Park Blvd, Suite C
        <br />
        Asheville, NC 28806
        <br />
        <Link
          as="a"
          color="red.500"
          href="https://goo.gl/maps/CFnfe2kehVXTV2gp7"
        >
          View Us On Google Maps
        </Link>
      </>
    ),
  },
  {
    title: `Message Us`,
    icon: <EmailIcon />,
    content: (
      <>
        To send us an email, please use the contact form{" "}
        <Link as="a" color="red.500" href="/contact#message/">
          here, on our contact page
        </Link>
        .
      </>
    ),
  },
  {
    title: `Browse Our FAQs`,
    icon: <Icon as={FaInfoCircle} />,
    content: (
      <>
        Answers to our most frequently asked questions are available{" "}
        <Link as={GatsbyLink} color="red.500" to="/faq/">
          here, on our FAQ page
        </Link>
        .
      </>
    ),
  },
]

export { IHelpItem, NeedHelpItems }
