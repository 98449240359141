const useBrowserStorage = require(`./useBrowserStorage`)

// drop-in replacement for useState(). use whenever state should be echoed
// to localStorage.
export const useLocalStorage = (key, initialValue) => {
  return useBrowserStorage(key, initialValue, `localStorage`)
}

// drop-in replacement for useState(). use whenever state should be echoed
// to sessionStorage.
export const useSessionStorage = (key, initialValue) => {
  return useBrowserStorage(key, initialValue, `sessionStorage`)
}
