import {
  Button,
  chakra,
  Flex,
  Heading,
  HStack,
  IconButton,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  StackDivider,
  Text,
  VStack,
} from "@chakra-ui/react"
import {
  ArrowBackIcon,
  ArrowForwardIcon,
  CloseIcon,
  EmailIcon,
  QuestionIcon,
} from "@chakra-ui/icons"
import * as React from "react"
import { Link as GatsbyLink } from "gatsby"
import { useLocation } from "@reach/router"

import Logo from "./logo"
import { Underlined } from "./ui"
import { MobileNavItems, MobileNavItem } from "../data/navItems"
import { IHelpItem, NeedHelpItems } from "../data/needHelp"

type MenuState = `nav` | `help`

type MenuView = MobileNavItem

interface MobileNavContentProps {
  isOpen?: boolean
  onClose?: () => void
}

interface MenuButtonProps {
  isActive: boolean
  isParent?: boolean
  label: string
  onClick?: () => void
}

function HelpButtons({ onClose, pathname, state, setState }): JSX.Element {
  return (
    <HStack align="center" justify="center" spacing={4} w="100%">
      {state === `nav` ? (
        <>
          <Button
            as={GatsbyLink}
            key="contact"
            colorScheme="green"
            leftIcon={<EmailIcon />}
            size="md"
            to="/contact/"
            onClick={() => {
              if (pathname.includes(`contact`)) {
                onClose()
              }
            }}
          >
            Contact Us
          </Button>
          <Button
            key="help"
            colorScheme="red"
            leftIcon={<QuestionIcon />}
            size="md"
            onClick={() => setState(`help`)}
          >
            Need Help?
          </Button>
        </>
      ) : state === `help` ? (
        <Button
          key="help-back"
          colorScheme="green"
          leftIcon={<ArrowBackIcon />}
          onClick={() => setState(`nav`)}
          size="md"
        >
          Back to Menu
        </Button>
      ) : null}
    </HStack>
  )
}

function MenuButton({
  isActive,
  isParent = false,
  label,
  onClick = null,
}: MenuButtonProps): JSX.Element {
  return (
    <Button
      bg={isActive && !isParent ? `red.50` : `transparent`}
      colorScheme={isActive ? `red` : `gray`}
      fontSize="xl"
      fontWeight="bold"
      onClick={onClick || null}
      rightIcon={isParent ? <ArrowForwardIcon /> : null}
      size="lg"
      variant="ghost"
    >
      {label}
    </Button>
  )
}

export function MobileNav(props: MobileNavContentProps) {
  const [state, setState] = React.useState<MenuState>(`nav`)
  const [view, setView] = React.useState<MenuView>(MobileNavItems)
  const [viewHistory, setViewHistory] = React.useState<MenuView[]>([])
  const [modalHeight, setModalHeight] = React.useState<number>(500)
  const { hash, pathname } = useLocation()

  const { isOpen, onClose } = props

  React.useEffect(() => {
    setModalHeight(window.innerHeight)
  }, [])

  // this is a nav menu first and foremost, so make sure it
  // always opens to nav items
  React.useEffect(() => {
    if (!isOpen && state !== `nav`) {
      setState(`nav`)
    }

    setModalHeight(window.innerHeight)
    // the menu should always open to the default view
    // with an empty history
    setView(MobileNavItems)
    setViewHistory([])
  }, [isOpen])

  const onCloseChild = (): void => {
    const [newView] = viewHistory
    setViewHistory(viewHistory.slice(1))
    setView(newView)
  }

  const onOpenChild = (child: MenuView): void => {
    setViewHistory([view, ...viewHistory])
    setView(child)
  }

  const currentPageSlug = pathname + hash

  return (
    <Modal
      isCentered
      isOpen={isOpen}
      motionPreset="none"
      onClose={onClose}
      scrollBehavior="inside"
      size="full"
    >
      <ModalOverlay>
        <ModalContent
          borderRadius={0}
          overflowY="scroll"
          style={{
            height: modalHeight,
            maxHeight: modalHeight,
            minHeight: modalHeight,
          }}
        >
          <ModalHeader px={6} py={0}>
            <Flex align="center" justify="space-between" h="4.5rem" w="100%">
              <Link as={GatsbyLink} to="/" aria-label="Link to homepage">
                <Logo />
              </Link>
              <IconButton
                aria-label="Close menu"
                autoFocus
                color="gray.800"
                display={{ base: "flex" }}
                fontSize="24px"
                icon={<CloseIcon />}
                onClick={() => {
                  state === `nav` ? onClose() : setState(`nav`)
                }}
                variant="ghost"
              />
            </Flex>
          </ModalHeader>
          <ModalBody align="center" d="flex">
            <VStack
              divider={<StackDivider />}
              justify="start"
              h="100%"
              overflowX="hidden"
              overflowY="scroll"
              py={2}
              spacing={4}
              w="100%"
            >
              {state === `nav` &&
                view.children.map((child: MobileNavItem, index: number) => {
                  const { children = [], label, slug } = child

                  const isParent = !!children.length

                  const isActive = isParent
                    ? !!children.find(({ slug }) => slug === currentPageSlug)
                    : currentPageSlug === slug

                  if (isParent) {
                    return (
                      <chakra.div key={index}>
                        <MenuButton
                          isActive={isActive}
                          isParent={isParent}
                          label={label}
                          onClick={() => onOpenChild(child)}
                        />
                      </chakra.div>
                    )
                  }
                  return (
                    <GatsbyLink key={index} to={slug}>
                      <MenuButton
                        isActive={isActive}
                        label={label}
                        onClick={() => {
                          if (pathname.includes(slug)) {
                            onClose()
                          }
                        }}
                      />
                    </GatsbyLink>
                  )
                })}
              {state === `nav` && viewHistory.length > 0 && (
                <Button
                  bg="transparent"
                  colorScheme="red"
                  fontSize="xl"
                  fontWeight="bold"
                  leftIcon={<ArrowBackIcon />}
                  onClick={onCloseChild}
                  size="lg"
                  variant="ghost"
                >
                  Back
                </Button>
              )}
              {state === `help` && (
                <chakra.div maxW="450px">
                  <Heading as="h3" fontSize={[`2xl`, `3xl`]} mb={4}>
                    <Underlined color="#FC8181">
                      How can we help you?
                    </Underlined>
                  </Heading>
                  {NeedHelpItems.map((item: IHelpItem, i: number) => (
                    <chakra.div key={i}>
                      <Heading
                        key={i}
                        as="h4"
                        fontSize={[`lg`, `xl`]}
                        mb={[1, 2]}
                      >
                        <chakra.span color="gray.600" mr={2}>
                          {item.icon}
                        </chakra.span>
                        {item.title}
                      </Heading>
                      <Text fontSize={[`lg`, `xl`]}>{item.content}</Text>
                      <br />
                    </chakra.div>
                  ))}
                </chakra.div>
              )}
            </VStack>
          </ModalBody>
          <ModalFooter
            bg="gray.50"
            borderTop="2px"
            borderTopColor="gray.100"
            borderTopStyle="solid"
          >
            <HelpButtons
              state={state}
              setState={setState}
              onClose={onClose}
              pathname={pathname}
            />
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}
