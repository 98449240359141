import { ValidFormName } from "../components/forms"

export type GTMEvent = `form_submission`

export type GTMEventAction =
  | `Form Submission`
  | `Phone Call`
  | `Content Download`

export type GTMEventCategory = ValidFormName | string | boolean | number

export type GTMEventLabel = `Lead` | `Non-Lead` | string

export interface GTMEventProps {
  eventName: GTMEvent
  eventAction: GTMEventAction
  eventCategory: GTMEventCategory
  eventLabel?: GTMEventLabel
}

function fireGtmEvent({
  eventName,
  eventAction,
  eventCategory,
  eventLabel = ``,
}: GTMEventProps) {
  const event = {
    event: eventName,
    event_action: eventAction,
    event_category: eventCategory,
    event_label: eventLabel,
  }

  if (typeof window !== `undefined` && `dataLayer` in window) {
    window[`dataLayer`].push(event)
  }
}

export { fireGtmEvent }
