import {
  Box,
  BoxProps,
  Button,
  Flex,
  HStack,
  Icon,
  Link,
  SimpleGrid,
  Stack,
  StackDivider,
  Text,
} from "@chakra-ui/react"
import * as React from "react"
import { Link as GatsbyLink } from "gatsby"
import {
  FaCheckCircle,
  FaFacebook,
  FaHouzz,
  FaInstagram,
  FaPhone,
} from "react-icons/fa"

import Logo from "./logo"
import NewsletterForm from "./newsletterForm"
import Associations from "./associations"
import { BuilderTrendIcon } from "../components/svgLogos"

interface FooterProps {
  showAssociations?: boolean
  showNewsletter?: boolean
}

const BuildertrendLogin: React.FC<BoxProps> = (props): JSX.Element => {
  return (
    <Box {...props}>
      <Button
        as={GatsbyLink}
        bg="blue.700"
        colorScheme="blue"
        size="md"
        to="/login/"
      >
        <BuilderTrendIcon mr={2} w="20px" />
        Login to Buildertrend
      </Button>
    </Box>
  )
}

const ColumnHeader: React.FC<{ heading: string } & BoxProps> = ({
  heading,
  ...rest
}): JSX.Element => {
  return (
    <Text
      color="gray.400"
      fontSize="sm"
      fontWeight="semibold"
      mb={4}
      textTransform="uppercase"
      letterSpacing="wider"
      {...rest}
    >
      {heading}
    </Text>
  )
}

export const Footer = ({
  showAssociations = true,
  showNewsletter = true,
}: FooterProps): JSX.Element => {
  return (
    <Box as="footer" bg="gray.900" pos="relative">
      {showAssociations && (
        <Box bg="gray.200" px={2} w="100vw">
          <Associations />
        </Box>
      )}
      {showNewsletter && (
        <Flex
          align="center"
          bgGradient={{
            base: `radial(250% 47%, red.700 90%, red.600 90%)`,
            md: `radial(200% 47%, , red.700 90%, red.600 90%)`,
          }}
          bgPos="50% 120%"
          bgSize="100% 500%"
          color="white"
          flexDir="column"
          justify="center"
          pb={[10, 12, 14]}
          pt={12}
          w="100%"
        >
          <Text color="red.100" fontSize="lg" fontWeight="semibold" mb={2}>
            Stay current on news, projects, and trends
          </Text>
          <Text
            fontSize={[`3xl`, `4xl`]}
            fontWeight="extrabold"
            letterSpacing="tight"
            lineHeight="1.2"
            mb={8}
            textAlign="center"
          >
            Subscribe to the Sundog Report
          </Text>
          <Box color="gray.800" maxW="500px" mx="auto">
            <NewsletterForm
              buttonColor="yellow"
              context={[]}
              eventLabel="Footer"
              fieldWidth="325px"
              size="lg"
            />
          </Box>
          <Stack
            align="center"
            direction={[`column`, `row`]}
            mt={4}
            spacing={4}
          >
            <HStack direction="row" spacing={2}>
              <Icon as={FaCheckCircle} color="yellow.400" />
              <Text>1-2 issues per month</Text>
            </HStack>
            <HStack direction="row" spacing={2}>
              <Icon as={FaCheckCircle} color="yellow.400" />
              <Text>Thoughtful & well-written</Text>
            </HStack>
          </Stack>
        </Flex>
      )}
      <Box
        as="footer"
        role="contentinfo"
        mx="auto"
        maxW="7xl"
        py="12"
        px={{ base: "4", md: "8" }}
      >
        <Stack spacing="10" divider={<StackDivider borderColor="gray.600" />}>
          <Stack
            direction={{ base: "column", lg: "row" }}
            spacing={{ base: "10", lg: "28" }}
          >
            <Box flex="1">
              <Logo color="light" width="128px" />
            </Box>
            <Stack
              direction={{ base: "column", md: "row" }}
              spacing={{ base: "10", md: "20" }}
            >
              <SimpleGrid
                columns={2}
                spacing={{ base: "10", md: "20", lg: "28" }}
                flex="1"
              >
                <Box minW="130px">
                  <ColumnHeader heading="Sundog Homes" />
                  <Stack color="gray.200">
                    <Link as={GatsbyLink} to="/home-plans/">
                      Our Plans
                    </Link>
                    <Link as={GatsbyLink} to="/get-started/">
                      Getting Started
                    </Link>
                    <Link as={GatsbyLink} to="/timber-frame-homes/">
                      Timber Frame Homes
                    </Link>
                  </Stack>
                </Box>
                <Box minW="130px">
                  <ColumnHeader heading="Privacy" />
                  <Stack color="gray.200">
                    <Link as={GatsbyLink} to="/privacy/">
                      Privacy Policy
                    </Link>
                    {/* <Link as={GatsbyLink} to="/cookies/">
                      Cookie Policy
                    </Link> */}
                    <Link as={GatsbyLink} to="/terms/">
                      Terms of Use
                    </Link>
                  </Stack>
                </Box>
              </SimpleGrid>
              <Stack spacing={4}>
                <ColumnHeader heading="Clients" mb={0} />
                <BuildertrendLogin />
              </Stack>
            </Stack>
          </Stack>
          <Stack
            direction={{ base: "column-reverse", md: "row" }}
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack align={[`center`, , `start`]} spacing="0px">
              <Text color="gray.200" fontSize="sm" fontWeight="medium">
                Give Us a Call:
                <Link color="red.300" href="tel:8287745720" ml={2}>
                  (828) 774-5720
                </Link>
              </Text>
              <Text color="gray.400" fontSize="sm" mt="-4px">
                &copy; Copyright {new Date().getFullYear()} Sundog Homes, Inc.
                All rights reserved.
              </Text>
            </Stack>
            <Box d="flex" justifyContent="center">
              <HStack justify="center" py={4}>
                <Link
                  aria-label="View Sundog Homes on Facebook"
                  href="https://www.facebook.com/sundoghomes"
                >
                  <Icon
                    as={FaFacebook}
                    boxSize="24px"
                    color="white"
                    mx={3}
                    transitionDuration="0.3s"
                    _hover={{ color: `red.500` }}
                  />
                </Link>
                <Link
                  aria-label="View Sundog Homes on Instagram"
                  href="https://www.instagram.com/sundoghomes/"
                >
                  <Icon
                    as={FaInstagram}
                    boxSize="24px"
                    color="white"
                    mx={3}
                    transitionDuration="0.3s"
                    _hover={{ color: `red.500` }}
                  />
                </Link>
                <Link
                  aria-label="View Sundog Homes on Houzz"
                  href="https://www.houzz.com/professionals/home-builders/sundog-homes-pfvwus-pf~849604738"
                >
                  <Icon
                    as={FaHouzz}
                    boxSize="24px"
                    color="white"
                    mx={3}
                    transitionDuration="0.3s"
                    _hover={{ color: `red.500` }}
                  />
                </Link>
              </HStack>
            </Box>
          </Stack>
        </Stack>
      </Box>
    </Box>
  )
}

export default Footer
