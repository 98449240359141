interface OpenCloseTime {
  hour: number
  meridian: `AM` | `PM`
}

interface DailyHours {
  day: string
  openAt?: OpenCloseTime
  closeAt?: OpenCloseTime
  specialCase?: string
}

type THours = DailyHours[]

const Hours: THours = [
  {
    day: `Monday`,
    openAt: {
      hour: 9,
      meridian: `AM`,
    },
    closeAt: {
      hour: 4,
      meridian: `PM`,
    },
  },
  {
    day: `Tuesday`,
    openAt: {
      hour: 9,
      meridian: `AM`,
    },
    closeAt: {
      hour: 4,
      meridian: `PM`,
    },
  },
  {
    day: `Wednesday`,
    openAt: {
      hour: 9,
      meridian: `AM`,
    },
    closeAt: {
      hour: 4,
      meridian: `PM`,
    },
  },
  {
    day: `Thursday`,
    openAt: {
      hour: 9,
      meridian: `AM`,
    },
    closeAt: {
      hour: 4,
      meridian: `PM`,
    },
  },
  {
    day: `Friday`,
    openAt: {
      hour: 9,
      meridian: `AM`,
    },
    closeAt: {
      hour: 4,
      meridian: `PM`,
    },
  },
  // {
  //   day: `Saturday`,
  //   specialCase: `Appt Only`,
  // },
  // {
  //   day: `Sunday`,
  //   specialCase: `Appt Only`,
  // },
]

export { DailyHours, Hours, OpenCloseTime, THours }
