import { DailyHours, OpenCloseTime, THours } from "../data/hours"

// template rules
// %t1 = start time                 %t2 = end time
// %m1 = start time meridian        %m2 = end time meridian
function formatHours(
  openAt: OpenCloseTime,
  closeAt: OpenCloseTime,
  template: string
): string {
  return template
    .replace(`%t1`, openAt.hour.toString())
    .replace(`%m1`, openAt.meridian)
    .replace(`%t2`, closeAt.hour.toString())
    .replace(`%m2`, closeAt.meridian)
}

// %d1 = start day                  %d2 = end day
// %dd1 = start day, abbreviated    %dd2 = end day, abbreviated
function formatDays(day1: string, day2: string, template: string): string {
  if (day1 === day2) {
    if (template.includes(`%dd1`)) {
      return day1.substring(0, 3)
    }
    return day1
  }
  return template
    .replace(`%d1`, day1)
    .replace(`%d2`, day2)
    .replace(`%dd1`, day1.substring(0, 3))
    .replace(`%dd2`, day2.substring(0, 3))
}

function getHoursFromDay(day: DailyHours, template: string): string {
  if (day.specialCase) {
    return day.specialCase
  }
  if (day.openAt && day.closeAt) {
    const { openAt, closeAt } = day
    return formatHours(openAt, closeAt, template)
  }
  return ``
}

interface IBucketLocation {
  bucket: number
  _closeAt?: string
  _openAt?: string
  _specialCase?: string
}

function getWeekdaySummary(
  week: THours,
  dateTemplate?: string,
  timeTemplate?: string
): string[][] {
  const hours = week.filter(
    ({ day }: DailyHours) =>
      day.toLowerCase() !== `saturday` && day.toLowerCase() !== `sunday`
  )
  return getWeeklySummary(hours, dateTemplate, timeTemplate)
}

function getWeeklySummary(
  week: THours,
  dateTemplate?: string,
  timeTemplate?: string
): string[][] {
  const timeTemp = timeTemplate || `%t1%m1 to %t2%m2`
  const dateTemp = dateTemplate || `%dd1 - %dd2`

  const summary: string[][] = []
  const locations: IBucketLocation[] = []
  const buckets: DailyHours[][] = []
  let bucketShift: boolean = false
  let currentBucket: number = 0

  week.forEach((day: DailyHours) => {
    let foundBucket = false
    const _openAt = JSON.stringify(day.openAt)
    const _closeAt = JSON.stringify(day.closeAt)
    const _specialCase = day.specialCase

    locations.forEach((location: IBucketLocation, index: number) => {
      if (
        (_openAt === location._openAt && _closeAt === location._closeAt) ||
        (day.specialCase && _specialCase === location._specialCase)
      ) {
        foundBucket = true
        if (bucketShift && location.bucket !== currentBucket) {
          locations[index].bucket = buckets.length
          buckets.push([day])
          bucketShift = false
        } else {
          buckets[location.bucket].push(day)
        }
      }
    })

    if (!foundBucket) {
      const bucket = buckets.length
      locations.push({ bucket, _openAt, _closeAt, _specialCase })
      buckets.push([day])
      if (locations.length) {
        bucketShift = true
        currentBucket = buckets.length - 1
      }
    }
  })

  buckets.forEach((bucket: DailyHours[]) => {
    const day1 = bucket[0].day
    const day2 = bucket[bucket.length - 1].day
    const dateStr = formatDays(day1, day2, dateTemp)
    if (bucket[0].openAt && bucket[0].closeAt) {
      const t1 = bucket[0].openAt
      const t2 = bucket[0].closeAt
      const timeStr = formatHours(t1, t2, timeTemp)
      summary.push([dateStr, timeStr])
    } else if (bucket[0].specialCase) {
      summary.push([dateStr, bucket[0].specialCase])
    }
  })
  return summary
}

export { getHoursFromDay, getWeekdaySummary, getWeeklySummary }
