import * as React from "react"
import { Global } from "@emotion/react"

const CustomFonts = () => (
  <Global
    styles={`
      @import url("https://p.typekit.net/p.css?s=1&k=sxb4vog&ht=tk&f=45408.45410&a=7374378&app=typekit&e=css");
      @font-face {
      font-family:"IvyPresto Display";
      src:url("https://use.typekit.net/af/6b5284/00000000000000007735e5ae/30/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n6&v=3") format("woff2"),url("https://use.typekit.net/af/6b5284/00000000000000007735e5ae/30/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n6&v=3") format("woff"),url("https://use.typekit.net/af/6b5284/00000000000000007735e5ae/30/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n6&v=3") format("opentype");
      font-display:swap;font-style:normal;font-weight:600;
      }
      @font-face {
      font-family:"IvyPresto Display";
      src:url("https://use.typekit.net/af/23c776/00000000000000007735e5af/30/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/23c776/00000000000000007735e5af/30/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/23c776/00000000000000007735e5af/30/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n7&v=3") format("opentype");
      font-display:swap;font-style:normal;font-weight:700;
      }
      `}
  />
)

export default CustomFonts
