import { HStack } from "@chakra-ui/react"
import * as React from "react"

import * as Logos from "./svgLogos"

interface AssociationsProps {
  iconTheme?: `light` | `dark`
}

function Associations({ iconTheme = `dark` }: AssociationsProps): JSX.Element {
  const useDarkIcons = iconTheme === `dark`
  const numLogos = 6
  const width = { base: `calc(100vw / ${numLogos + 2})`, lg: `75px` }

  const props = { maxH: `75px`, maxW: `75px`, width }

  return (
    <HStack
      align="center"
      filter={useDarkIcons ? `brightness(0)` : ``}
      flexWrap="wrap"
      justify="space-evenly"
      maxW="1260px"
      mx="auto"
      opacity={useDarkIcons ? `0.5` : `1`}
      py={{ base: 3, lg: 6 }}
      w="100%"
    >
      <Logos.AshevilleHomeBuildersAssociation {...props} />
      <Logos.GreenBuiltAlliance {...props} />
      <Logos.NHBA {...props} />
      <Logos.TwoTenHomebuyersWarranty {...props} />
      <Logos.EqualHousingOpportunity {...props} />
      <Logos.EnergyStar {...props} />
    </HStack>
  )
}

export default Associations

export { AssociationsProps }
