const React = require(`react`)

// wrapper around React.useState() that backs up state to localStorage (default) or
// sessionStorage. for the sake of consistency and readability, it's recommended to
// use useLocalStorage and/or useSessionStorage (defined in ./hooks.tsx) rather than
// than using this function directly.
const useBrowserStorage = (key, initialValue, location = `localStorage`) => {
  const [stored, setStored] = React.useState(() => {
    try {
      if (typeof window === undefined) return initialValue

      const item = window[location]?.getItem?.(key)
      if (item) {
        return JSON.parse(item)
      } else {
        window[location]?.setItem?.(key, JSON.stringify(initialValue))
        return initialValue
      }
    } catch (error) {
      console.warn(error)
    }
  })

  const setStoredValue = value => {
    if (typeof window === undefined) return

    try {
      const valueToStore = value instanceof Function ? value(stored) : value
      setStored(valueToStore)
      window[location].setItem(key, JSON.stringify(valueToStore))
    } catch (error) {
      console.warn(error)
    }
  }

  return [stored, setStoredValue]
  // const [stored, setStored] = React.useState(initialValue)
  // return [stored, setStored]
}

module.exports = useBrowserStorage
