import * as React from "react"
import { Box } from "@chakra-ui/react"

import { Sundog } from "../data/svgForLogos"

interface LogoProps {
  appearance?: `flat` | `textured`
  color?: `light` | `dark`
  height?: number | string
  width?: number | string
}

function Logo({
  appearance = `flat`,
  color = `dark`,
  height = `auto`,
  width = 100,
}: LogoProps): JSX.Element {
  const logoTheme = color === `dark` ? `Dark` : `Light`
  const logoAppearance = appearance === `flat` ? `Flat` : `Textured`
  const logoKey = `${logoTheme}${logoAppearance}`
  return (
    <Box
      dangerouslySetInnerHTML={{ __html: Sundog[logoKey] }}
      h={height}
      w={width}
    />
  )
}

export default Logo
